import "./src/style.scss"
// import "slick-carousel/slick/slick.scss"
// import "slick-carousel/slick/slick-theme.scss"

// import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/400.css";  // Verified
// import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/600.css";  // Verified
// import "@fontsource/open-sans/700.css";
// import "@fontsource/open-sans/800.css";
// import "@fontsource/poppins/100.css";
// import "@fontsource/poppins/200.css";
import "@fontsource/poppins/300.css";  // Verified
// import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";  // Verified
import "@fontsource/poppins/600.css";  // Verified
// import "@fontsource/poppins/700.css";
import "@fontsource/poppins/800.css";  // Verified
// import "@fontsource/poppins/900.css";

import {config} from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
config.autoAddCss = false;
