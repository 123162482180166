module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1000,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon-512x512.png","name":"Secuma - Applicatieveiligheid verbeteren","short_name":"Secuma","description":"Applicatieveiligheid verbeteren met tools, technologie en expertise. Automatiseer en integreer security best practices in je softwareontwikkelproces.","lang":"nl","localize":[{"start_url":"/en/","lang":"en","name":"Secuma - Improving Application Security","short_name":"Secuma","description":"Improve Application Security using tools, technology and expertise. Automate and integrate security practices in your development lifecycle."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6710109b4e570b2a7904fe9de913888c"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
